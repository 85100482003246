<template>
  <v-container pa-2 fluid class="fill-height"
               :class="{ 'img-fondo': !$vuetify.breakpoint.smAndDown, 'img-fondo-mobile': $vuetify.breakpoint.smAndDown }">
    <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '5'" align="center">
      <v-card width="500" class="pa-6" color="transparent" flat>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row align="center" justify="center">
                <v-col cols="12" class="text-center mb-5">
                  <img :src="require('./../assets/logoVanjupe.png')" :width="$vuetify.breakpoint.smAndDown ? '200' : '350'"/>
                </v-col>
                <v-col cols="12" class="text-center">
                  <h1 class="blue--text text--darken-3 font-weight-bold">Bienvenido</h1>
                </v-col>
                <v-col cols="12" class="text-center">
                  <h2>Sistema de envios Vanjupe</h2>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-row>
                <v-col cols="12">
                  <h3 class="grey--text">Inicie sesión con su cuenta microsoft</h3>
                </v-col>
                <v-col cols="12">
                  <v-btn outlined large class="indigo--text v-btn-login" rounded @click="loginMicrosoft">
                    <v-icon size="1x">mdi-microsoft</v-icon>
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
  import AuthConst from '../config/AuthConst'
  import firebase from '../config/Firebase'
  import crud from '../services/CrudService'

  export default {
    name: "Login",
    data () {
      return {

      }
    },
    created () {
      let vm = this
      vm.$loader = true
      firebase.getRedirectResult()
        .then(({token, user}) => {
          if (token) {
            searchUsuarioData(user)
              .then(usuarioEncontrado => {
                usuarioEncontrado.photoURL = user.photoURL
                localStorage.setItem(AuthConst.ACCESS_TOKEN_KEY, token)
                guardarUsuario(vm, usuarioEncontrado)
              })
              .catch(error => {
                vm.$showAlerta('600', error.color , 'mdi-check-circle-outline', error.message, true )
              })
              .finally(() => (vm.$loader = false))
          } else {
            vm.$loader = false
          }
        })
        .catch(error => {
          if (error.code === 'auth/timeout') {
            vm.$showAlerta('600', 'warning' , 'mdi-check-circle-outline', 'Se ha agotado el tiempo de espera, verifique su conexión de internet e inténtelo nuevamente.', true )
          }
          vm.$loader = false
        })
    },
    methods: {
      loginMicrosoft () {
        firebase.microsoftSignIn()
      }
    }
  }

  function searchUsuarioData (user) {
    return new Promise((resolve, reject) => {
      crud.buscarPorCampo('usuarios', user.email, 'email')
        .then(usuarios => {
          if (usuarios.length > 0) {
            // Sí existe un usuario ya autenticado
            resolve(usuarios[0])
          } else {
            // Sí no existe el usuario
            reject({ color: 'warning', message: 'Está cuenta aún no ha sido confirmada, por favor vuelva a intentarlo más tarde.' })
          }
        })
        .catch(() => {
          reject({ color: 'error', message: 'No fue posible iniciar sesión, por favor vuelva a intentarlo más tarde.' })
        })
    })
  }

  async function guardarUsuario (vm, usuario) {
    localStorage.setItem(AuthConst.CURRENT_USER_KEY, JSON.stringify(usuario))
    vm.$usuario = usuario

    vm.$vuetify.theme.themes.light.primary = usuario.tema.color
    vm.$vuetify.theme.themes.dark.primary = usuario.tema.color
    vm.$vuetify.theme.dark = usuario.tema.isDark

    vm.$router.push({ name: 'Dashboard' })
  }
</script>

<style scoped>
  .img-fondo {
    background: url('../assets/login_533x255.svg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .img-fondo-mobile {
    background: url('../assets/login_mobile.svg') no-repeat top center fixed;
    -webkit-background-size: initial;
    -moz-background-size: initial;
    -o-background-size: initial;
    background-size: initial;
  }
</style>
